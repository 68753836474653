/* eslint no-console: 0 */
import { isPresent } from '@healthiqeng/core.util.is-present';
import { LoggerInterface, LogLevel } from '@healthiqeng/core.services.logger-base';

export class Logger implements LoggerInterface {
  private readonly level: LogLevel;
  constructor(level?: LogLevel) {
    this.level = isPresent(level) ? level : Logger.defaultLevel();
  }

  public crit(error: Error | string, ...args: any[]): void {
    if (this.level >= LogLevel.Crit) {
      const message = error instanceof Error ? error.message : error;
      const stack = error instanceof Error ? error.stack : undefined;
      console.error(`${Logger.timestamp()} HIQERROR ${message}`, ...args);
      console.error(stack);
    }
  }

  public error(error: Error | string, ...args: any[]): void {
    if (this.level >= LogLevel.Error) {
      console.error(Logger.timestamp(), error, ...args);
    }
  }

  public warn(message: Error | string, ...args: any[]): void {
    if (this.level >= LogLevel.Warn) {
      console.warn(Logger.timestamp(), message, ...args);
    }
  }

  public info(...args: any[]): void {
    if (this.level >= LogLevel.Info) {
      console.info(Logger.timestamp(), ...args);
    }
  }

  public http(...args: any[]): void {
    if (this.level >= LogLevel.Http) {
      console.log(Logger.timestamp(), ...args);
    }
  }

  public debug(...args: any[]): void {
    if (this.level >= LogLevel.Http) {
      console.debug(Logger.timestamp(), ...args);
    }
  }

  private static defaultLevel() {
    switch (process.env.NODE_ENV) {
      case 'development':
      case 'test':
        return LogLevel.Debug;
      case 'staging':
        return LogLevel.Info;
      case 'production':
        return LogLevel.Warn;
      default:
        return LogLevel.Info;
    }
  }

  private static timestamp() {
    return (new Date()).toISOString();
  }
}
