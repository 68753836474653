import { IEventBus } from '@healthiqeng/core.services.event-bus';
import { PollingTimerEvent } from './PollingTimerEvent';
import { PollingTimerInterface, PollingTimerOptions } from './PollingTimer.interfaces';

export class PollingTimer implements PollingTimerInterface {
  private eventBus: IEventBus;
  private readonly tag: string;
  private readonly initialDelay: number;
  private readonly factor: number;
  private readonly maxDelay?: number;
  private readonly maxAttempts?: number;
  private cumulativeDelay: number = 0;
  private cumulativeAttempts: number = 0;
  private currentDelay: number;
  private running: boolean = false;
  private timeoutHandle: ReturnType<typeof setTimeout>;
  constructor(eventBus: IEventBus, options: PollingTimerOptions) {
    this.eventBus = eventBus;
    this.tag = options.tag;
    this.initialDelay = options.initialDelay ?? 1000;
    this.currentDelay = options.initialDelay ?? 1000;
    this.factor = options.factor ?? 2;
    this.maxAttempts = options.maxAttempts;
    this.maxDelay = options.maxDelay;
  }

  public start() {
    if (this.running) return;
    this.running = true;
    this.emit();
  }

  public stop() {
    this.running = false;
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
    }
  }

  public restart() {
    this.cumulativeAttempts = 0;
    this.cumulativeDelay = 0;
    this.currentDelay = this.initialDelay;
    this.stop();
    this.start();
  }

  private emit() {
    if (this.hasReachedEnd()) {
      return;
    }
    this.eventBus.emit(new PollingTimerEvent(this.tag, this.cumulativeDelay));
    this.cumulativeDelay += this.currentDelay;
    this.cumulativeAttempts += 1;
    this.currentDelay *= this.factor;
    this.timeoutHandle = setTimeout(() => this.emit(), this.currentDelay);
  }

  private hasReachedEnd() {
    return this.hasReachedMaxAttempts() || this.hasReachedMaxDelay();
  }

  private hasReachedMaxDelay() {
    return this.maxDelay && this.cumulativeDelay >= this.maxDelay;
  }

  private hasReachedMaxAttempts() {
    return this.maxAttempts && this.cumulativeAttempts >= this.maxAttempts;
  }
}
