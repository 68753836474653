import fetch from 'isomorphic-fetch';
import { InternalAuthService } from '@healthiqeng/core.services.internal-auth';
import { HiqCrmClientRest } from '@healthiqeng/core.clients.hiq-crm-rest';
import { config } from '../../config';
import {
  ListOAuthAppsResponse, ListOAuthAppsResponseItem, ReportType, ValidateCarrierReportResponse,
} from '@hiq/crm.types';
import { IUrlService } from '@healthiqeng/core.services.url';
import { encodeBase64Url } from '@healthiqeng/core.util.base64-url';

export class HiqCrmService {
  private hiqCrmClient: HiqCrmClientRest;
  private internalAuthService: InternalAuthService;
  private urlService: IUrlService;
  private initialized: boolean = false;

  constructor(internalAuthService: InternalAuthService, urlService: IUrlService) {
    this.internalAuthService = internalAuthService;
    this.urlService = urlService;
  }

  private async initialize() {
    if (this.initialized) return;
    const token = await this.internalAuthService.getIdToken();
    this.hiqCrmClient = new HiqCrmClientRest(config.HIQ_CRM.HOST, fetch, token);
  }

  public async listOAuthApps(): Promise<ListOAuthAppsResponse> {
    await this.initialize();
    return this.hiqCrmClient.listOAuthApps();
  }

  public async getOAuthApp(id: string): Promise<ListOAuthAppsResponseItem> {
    const response = await this.listOAuthApps();
    return response.apps.find(({ id: appId }) => id === appId);
  }

  public getOAuthAppUrl(app: ListOAuthAppsResponseItem): string {
    const currentUrl = this.urlService.getUrl().toString();
    const oauthState = encodeBase64Url(currentUrl);
    const queryParams = [
      `client_id=${app.clientId}`,
      `redirect_uri=${app.redirectUri}`,
      'response_type=code',
      `scope=${encodeURIComponent(app.scopes.join(' '))}`,
      `state=${oauthState}`,
      ...Object.entries(app.params ?? {})
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`),
    ].join('&');
    return `${app.host}?${queryParams}`;
  }

  public async getCarrierUploadUrl(filename: string, type: string): Promise<string> {
    await this.initialize();
    return this.hiqCrmClient.getCarrierUploadUrl(filename, type);
  }

  public async createSignedFileUploadUrl(fileName: string, type: string, leadId: string): Promise<string> {
    await this.initialize();
    return this.hiqCrmClient.createSignedFileUploadUrl(fileName, type, leadId);
  }

  public async validateCarrierReport(filename: string, type: ReportType): Promise<ValidateCarrierReportResponse> {
    await this.initialize();
    return this.hiqCrmClient.validateCarrierReport(filename, type);
  }
}
