import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { LocalFormAnswersQueryData, LocalFormAnswersQueryResult } from './useLocalFormAnswersQuery.interfaces';
import { QueryResult } from '@apollo/react-common';

export const LOCAL_FORM_ANSWERS_QUERY = gql`
  query GetLocalFormAnswersQuery {
    localFormAnswers @client
  }
`;

export function useLocalFormAnswersQuery() {
  const result = useQuery<LocalFormAnswersQueryData>(LOCAL_FORM_ANSWERS_QUERY, {
    fetchPolicy: 'cache-only',
  });
  if (result?.data?.localFormAnswers && typeof result?.data?.localFormAnswers === 'string') {
    result.data = {
      localFormAnswers: JSON.parse(result.data.localFormAnswers),
    };
  }
  return result as any as QueryResult<LocalFormAnswersQueryResult, Record<string, any>>;
}
