export function getFormDataId(form: any) {
  return `form:${form.leadId}:${form.referenceId}`;
}

export function getQuestionDataId(question: any) {
  return `question:${question.leadId}:${question.id}`;
}

export function getQuestionFlowDataId(questionFlow: any) {
  return `questionFlow:${questionFlow.leadId}:${questionFlow.referenceId}`;
}
