import {
  Fetch, HttpAuthType, HttpVerb, IsomorphicHttpClient,
} from '@healthiqeng/core.clients.isomorphic-http-client';
import { FormAnswers } from '@hiq/crm.types';
import { JsonCompatible } from '@healthiqeng/core.types';

export class AWSRestClient extends IsomorphicHttpClient {
  constructor(host: string, fetch: Fetch, apiKey: string) {
    super(host, fetch, {
      type: HttpAuthType.ApiKey,
      apiKey,
    });
  }

  public async storeAnswers(leadId: string, callUrlPath: string, answers: FormAnswers, extra?: JsonCompatible): Promise<void> {
    return this.request(HttpVerb.Post, '/calldata', {
      body: {
        leadId, callUrlPath, answers, extra,
      },
    });
  }
}
