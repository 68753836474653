import {
  BillingFrequency,
  BillingFrequencyMap,
  MedicareApplicationType,
  MedicareApplicationTypeMap,
  MedicareSupplementPlanType,
  Gender,
  PropertyAndCasualtyPolicyTermLength,
  PropertyAndCasualtyPolicyTermLengthLabelMap,
} from '@hiq/crm.types';

export const BillingFrequencyOptions = Object.values(BillingFrequency).map((value) => ({
  label: BillingFrequencyMap[value],
  value,
}));

export const MedicareApplicationTypeOptions = Object.values(MedicareApplicationType).map((value) => ({
  label: MedicareApplicationTypeMap[value],
  value,
}));

export const MedicarePlanTypeOptions = Object.values(MedicareSupplementPlanType).map((value) => ({
  label: value,
  value,
}));

export const GenderMap = {
  [Gender.Male]: 'Male',
  [Gender.Female]: 'Female',
};

export const GenderOptions = Object.values(Gender).map((value) => ({
  label: GenderMap[value],
  value,
}));

export const TobaccoOptions = [
  { label: 'Tobacco', value: true },
  { label: 'Non-Tobacco', value: false },
];

export const HouseholdDiscountOptions = [
  { label: 'Applied', value: true },
  { label: 'Not Applied', value: false },
];

export const medicareAgeOptions: Array<{label: string; value: number;}> = [];
for (let i = 64; i < 120; i++) {
  medicareAgeOptions.push({ label: `${i}`, value: i });
}

export const PropertyAndCasualtyPolicyTermLengthOptions = Object.values(PropertyAndCasualtyPolicyTermLength).map((value) => ({
  label: PropertyAndCasualtyPolicyTermLengthLabelMap[value],
  value,
}));

export const CLIENT_REFUSED_OPTION = { label: 'Client Refused', value: 'CLIENT_REFUSED' };
export const NOT_SPECIFIED_OPTION = { label: 'Other - Not Specified', value: 'NOT_SPECIFIED' };
