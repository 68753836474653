import { LOCAL_FORM_ANSWERS_QUERY } from '../../../graphql/queries/useLocalFormAnswersQuery';
import { CustomResolver } from 'apollo-boost';

export const localFormAnswers: CustomResolver = (_, { answers }, { cache }) => {
  const { localFormAnswers } = cache.readQuery({ query: LOCAL_FORM_ANSWERS_QUERY });
  const existingAnswers = typeof localFormAnswers === 'string' ? JSON.parse(localFormAnswers) : localFormAnswers;
  const mutatedLocalFormAnswers = {
    ...existingAnswers,
    ...answers,
  };
  cache.writeData({
    data: {
      localFormAnswers: JSON.stringify(mutatedLocalFormAnswers),
    },
  });
  return mutatedLocalFormAnswers;
};

export const LOCAL_FORM_ANSWERS_RESOLVERS = {
  localFormAnswers,
};
