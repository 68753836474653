import { IEventBusEvent } from '@healthiqeng/core.services.event-bus';

export class PollingTimerEvent implements IEventBusEvent {
  public readonly type: string = 'POLLING_TIMER_EVENT';

  constructor(
    public readonly tag: string,
    public readonly delay: number,
  ) {}
}
