/* eslint no-underscore-dangle: 0 */
import ApolloClient, {
  InMemoryCache,
  defaultDataIdFromObject,
  IntrospectionFragmentMatcher,
} from 'apollo-boost';
import { isPresent } from '@healthiqeng/core.util.is-present';
import introspectionQueryResultData from './fragmentTypes.json';
import { config } from '../../config';
import { internalAuthService, logger } from '../singletons';
import { getFormDataId, getQuestionDataId, getQuestionFlowDataId } from '../../util/dataIds';
import { HttpRequestError } from '@healthiqeng/core.clients.isomorphic-http-client';
import { redirectToLogin } from '@healthiqeng/core.hooks.use-internal-auth';
import { urlService } from '@healthiqeng/core.services.url';
import { LOCAL_FORM_ANSWERS_RESOLVERS } from './resolvers';
import { captureEvent } from '@sentry/gatsby';
import { TRM_JWT_HEADER } from '../../constants';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject: (obj: any) => {
    if (isPresent(obj.hash)) {
      return obj.hash;
    }
    switch (obj.__typename) {
      case 'QuestionFlow':
        return getQuestionFlowDataId(obj);
      case 'Form':
        return getFormDataId(obj);
      case 'Question':
        return getQuestionDataId(obj);
      case 'FormAnswers':
        return `${obj.__typename}:${obj.leadId}`;
      default:
        return defaultDataIdFromObject(obj);
    }
  },
});

// Initializes local state data
cache.writeData({
  data: {
    localFormAnswers: '{}',
  },
});

const redirectToLoginPage = () => redirectToLogin(urlService, {
  authService: internalAuthService,
  logger,
  loginUrl: `https://${config.AWS.COGNITO.DOMAIN}.auth.${config.AWS.COGNITO.REGION}.amazoncognito.com/login`,
  redirectUrl: config.AWS.COGNITO.REDIRECT_URI,
  scopes: ['email', 'openid'],
  clientId: config.AWS.COGNITO.CLIENT_ID,
});

const redirectToInvalidUserPage = () => {
  window.location.href = `${window.location.origin}/user-not-found`;
};

export const graphqlClient = new ApolloClient<InMemoryCache>({
  uri: ({ operationName }) => `${config.HIQ_CRM.HOST}/${config.HIQ_CRM.GRAPHQL_ENDPOINT}/${operationName}`,
  cache,
  fetch: async (input, init) => {
    const response = await window.fetch(input, init);
    const trmAuthHeader = response.headers.get(TRM_JWT_HEADER);
    if (trmAuthHeader) {
      internalAuthService.setTrmJWT(trmAuthHeader);
    }
    return response;
  },
  request: async (operation) => {
    try {
      const token = await internalAuthService.getIdToken();
      operation.setContext({
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
          [TRM_JWT_HEADER]: internalAuthService.getTrmJWT() || '',
        },
      });
    } catch (error) {
      if (error.type === HttpRequestError.type && /invalid_grant/.test(error.message)) {
        redirectToLoginPage();
        return;
      }
      logger.error(error);
      captureEvent({
        level: 'error',
        message: `[AWS Cognito Error] ${error.message}`,
        extra: error instanceof HttpRequestError
          ? { ...error }
          : undefined,
        tags: {
          type: 'aws',
          service: 'cognito',
        },
      });
    }
  },
  resolvers: {
    Mutation: {
      ...LOCAL_FORM_ANSWERS_RESOLVERS,
    },
  },
  onError: (errorResponse) => {
    const { operation, graphQLErrors, networkError } = errorResponse;
    const statusCode = operation.getContext()?.response?.status;

    // If forbidden, tokens are valid but user has no access to our features.
    // In that case, we redirect them to the invalid user page so that they know they
    // need to ask for IT support.
    if (statusCode === 403) {
      internalAuthService.setTrmJWT(undefined);
      redirectToInvalidUserPage();
      return;
    }
    const errorMessage = graphQLErrors
      ? graphQLErrors.map(({ message }) => message).join('; ')
      : networkError.message;
    const path = graphQLErrors
      ? graphQLErrors.map(({ path }) => path).join('; ')
      : undefined;
    const { operationName, variables } = operation;

    captureEvent({
      level: 'error',
      message: `[${operationName}] ${errorMessage}`,
      extra: {
        path,
        variables,
        statusCode,
        isNetworkError: !!networkError,
      },
      tags: {
        type: 'graphql',
        operation: operationName,
      },
    });
  },
});
