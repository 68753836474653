import fetch from 'isomorphic-fetch';
import { EventBus } from '@healthiqeng/core.services.event-bus';
import { InternalAuthService } from '@healthiqeng/core.services.internal-auth';
import { urlService } from '@healthiqeng/core.services.url';
import { Logger } from './Logger';
import { config } from '../config';
import { PollingTimer } from './PollingTimer';
import { HiqCrmService } from './HiqCrmService';
import { AWSService } from './AWSService';

const TWENTY_NINE_DAYS = 1000 * 60 * 60 * 24 * 29;
export const eventBus = new EventBus();
export const logger = new Logger();
export const internalAuthService = new InternalAuthService({
  fetch,
  refreshTokenTTL: TWENTY_NINE_DAYS,
  cognitoOAuthConfig: {
    clientId: config.AWS.COGNITO.CLIENT_ID,
    domain: config.AWS.COGNITO.DOMAIN,
    region: config.AWS.COGNITO.REGION,
    redirectUri: config.AWS.COGNITO.REDIRECT_URI,
  },
  logger,
});

export const contactDataPollingTimer = new PollingTimer(eventBus, {
  tag: 'CONTACT_DATA',
  initialDelay: 1000,
  factor: 2,
  maxDelay: 1000 * 60 * 5,
});

export const agentDataPollingTimer = new PollingTimer(eventBus, {
  tag: 'AGENT_DATA',
  initialDelay: 1000,
  factor: 1,
  maxDelay: 1000 * 60 * 5,
});

const CURRENT_CALL_INTERVAL = 30 * 1000;
export const currentCallPollingTimer = new PollingTimer(eventBus, {
  tag: 'CURRENT_CALL',
  initialDelay: 600,
  factor: config.ENV === 'production' ? 1.2 : 100, // We don't want it to poll like crazy in staging/UAT/dev
  maxAttempts: (2 * 60 * 60 * 1000) / CURRENT_CALL_INTERVAL, // Times out after 2 hours
});

export const hiqCrmService = new HiqCrmService(internalAuthService, urlService);

export const awsService = new AWSService();
