/* eslint-disable no-await-in-loop */
import { JsonCompatible } from '@healthiqeng/core.types';
import { sleep } from '@healthiqeng/core.util.sleep';
import { FormAnswers } from '@hiq/crm.types';
import fetch from 'isomorphic-fetch';
import { AWSRestClient } from './AWSRestClient';
import { config } from '../../config';
import { Logger } from '../Logger';

const logger = new Logger();

export class AWSService {
  private awsRestClient: AWSRestClient;
  private initialized: boolean = false;

  private async initialize() {
    if (this.initialized) return;
    const { API_KEY, URL } = config.AWS.API_GATEWAY.STORE_ANSWERS;
    this.awsRestClient = new AWSRestClient(URL, fetch, API_KEY);
  }

  public async storeAnswers(leadId: string, callUrlPath: string, answers: FormAnswers, extra?: JsonCompatible): Promise<void> {
    await this.initialize();

    for (let retryCount = 0; ; retryCount++) {
      try {
        await this.awsRestClient.storeAnswers(leadId, callUrlPath, answers, extra);
        break;
      } catch (err) {
        // eslint-disable-next-line
        if (retryCount >= config.AWS.API_GATEWAY.STORE_ANSWERS.RETRIES || 3) {
          logger.error('Max retries reached when saving SDR answers copy.');
          logger.error(err);
          break;
        }
        await sleep(1000);
      }
    }
  }
}
