const THIRTY_DAYS = 30 * 24 * 60 * 60 * 1000;
const configByEnv = {
  development: {
    ENV: 'development',
    HIQ_CRM: {
      HOST: process.env.HIQ_CRM__HOST || 'http://hiq-local.healthiq.com:3000',
      GRAPHQL_ENDPOINT: process.env.HIQ_CRM__GRAPHQL_ENDPOINT || 'graphql',
      SPEED_DIAL_MAPPING_ENDPOINT: process.env.HIQ_CRM__SPEED_DIAL_MAPPING_ENDPOINT || 'speed-dial-mapping',
    },
    AWS: {
      COGNITO: {
        REGION: process.env.AWS__COGNITO__REGION || 'us-east-1',
        USER_POOL_ID: process.env.AWS__COGNITO__USER_POOL_ID || 'us-east-1_n0D86Bu59',
        CLIENT_ID: process.env.AWS__COGNITO__CLIENT_ID || '42ee80krlb55c9qms93d9tifi9',
        DOMAIN: process.env.AWS__COGNITO__DOMAIN || 'healthiq-internal-2023',
        REDIRECT_URI: process.env.AWS__COGNITO__REDIRECT_URI || 'https://crm.healthiq.com/redirect',
        REFRESH_TOKEN_TTL: Number.parseInt(process.env.AWS__COGNITO__REFRESH_TOKEN_TTL || `${THIRTY_DAYS}`),
      },
      API_GATEWAY: {
        STORE_ANSWERS: {
          URL: process.env.AWS__API_GATEWAY__STORE_ANSWERS_URL || 'https://httpbin.org/anything',
          API_KEY: process.env.AWS__API_GATEWAY__STORE_ANSWERS_API_KEY || '',
          RETRIES: process.env.AWS__API_GATEWAY__STORE_ANSWERS_RETRIES || 3,
        },
      },
    },
    OAUTH: {
      REDIRECT_URI: process.env.OAUTH__REDIRECT_URI || 'http://hiq-local.healthiq.com:8000/oauth',
    },
    USE_MOCK: {
      DIALER_SERVICE: true,
    },
    PERISCOPE: {
      MEDICARE_SALES_DASHBOARD: process.env.PERISCOPE__MEDICARE_SALES_DASHBOARD
        || 'https://app.periscopedata.com/shared/855f8724-7562-4998-bc62-b751b65c1bba',
      PNC_SALES_DASHBOARD: process.env.PERISCOPE__PNC_SALES_DASHBOARD
        || 'https://app.periscopedata.com/shared/0e4199f1-74b3-4f16-ad0a-f0457afe6f59',
    },
    FEATURE_FLAGS: {},
    CSG: {
      EAPP_HOST: process.env.CSG__EAPP_HOST || 'https://eapp-staging.csgactuarial.com',
    },
    AMPLITUDE: {
      RATE_LIMIT: Number(process.env.AMPLITUDE__RATE_LIMIT) || 10,
      ENABLED: process.env.AMPLITUDE__ENABLED ? !!process.env.AMPLITUDE__ENABLED : false,
    },
    SUNFIRE: {
      HOST: 'https://training.sunfirematrix.com',
    },
  },
  test: {
    ENV: 'test',
    HIQ_CRM: {
      HOST: process.env.HIQ_CRM__HOST || 'http://hiq-local.healthiq.com:3000',
      GRAPHQL_ENDPOINT: process.env.HIQ_CRM__GRAPHQL_ENDPOINT || 'graphql',
      SPEED_DIAL_MAPPING_ENDPOINT: process.env.HIQ_CRM__SPEED_DIAL_MAPPING_ENDPOINT || 'speed-dial-mapping',
    },
    AWS: {
      COGNITO: {
        REGION: process.env.AWS__COGNITO__REGION || 'us-east-1',
        USER_POOL_ID: process.env.AWS__COGNITO__USER_POOL_ID || 'us-east-1_n0D86Bu59',
        CLIENT_ID: process.env.AWS__COGNITO__CLIENT_ID || '4fv5739239hhsi9if67755gs38',
        DOMAIN: process.env.AWS__COGNITO__DOMAIN || 'healthiq-internal-2023',
        REDIRECT_URI: process.env.AWS__COGNITO__REDIRECT_URI || 'https://crm.healthiq.com/redirect',
        REFRESH_TOKEN_TTL: Number.parseInt(process.env.AWS__COGNITO__REFRESH_TOKEN_TTL || `${THIRTY_DAYS}`),
      },
      API_GATEWAY: {
        STORE_ANSWERS: {
          URL: process.env.AWS__API_GATEWAY__STORE_ANSWERS_URL || 'https://8ebno9zd1j.execute-api.us-east-1.amazonaws.com/api',
          API_KEY: process.env.AWS__API_GATEWAY__STORE_ANSWERS_API_KEY || '',
          RETRIES: process.env.AWS__API_GATEWAY__STORE_ANSWERS_RETRIES || 3,
        },
      },
    },
    USE_MOCK: {
      DIALER_SERVICE: true,
    },
    PERISCOPE: {
      MEDICARE_SALES_DASHBOARD: process.env.PERISCOPE__MEDICARE_SALES_DASHBOARD
        || 'https://app.periscopedata.com/shared/855f8724-7562-4998-bc62-b751b65c1bba',
      PNC_SALES_DASHBOARD: process.env.PERISCOPE__PNC_SALES_DASHBOARD
        || 'https://app.periscopedata.com/shared/0e4199f1-74b3-4f16-ad0a-f0457afe6f59',
    },
    FEATURE_FLAGS: {},
    CSG: {
      EAPP_HOST: process.env.CSG__EAPP_HOST || 'https://eapp-staging.csgactuarial.com',
    },
    AMPLITUDE: {
      RATE_LIMIT: Number(process.env.AMPLITUDE__RATE_LIMIT) || 10,
      ENABLED: process.env.AMPLITUDE__ENABLED ? !!process.env.AMPLITUDE__ENABLED : false,
    },
    SUNFIRE: {
      HOST: 'https://training.sunfirematrix.com',
    },
  },
  staging: {
    ENV: 'staging',
    HIQ_CRM: {
      HOST: process.env.HIQ_CRM__HOST || 'https://hiq-crm-staging.healthiq.com',
      GRAPHQL_ENDPOINT: process.env.HIQ_CRM__GRAPHQL_ENDPOINT || 'graphql',
      SPEED_DIAL_MAPPING_ENDPOINT: process.env.HIQ_CRM__SPEED_DIAL_MAPPING_ENDPOINT || 'speed-dial-mapping',
    },
    AWS: {
      COGNITO: {
        REGION: process.env.AWS__COGNITO__REGION || 'us-east-1',
        USER_POOL_ID: process.env.AWS__COGNITO__USER_POOL_ID || 'us-east-1_n0D86Bu59',
        CLIENT_ID: process.env.AWS__COGNITO__CLIENT_ID || '4fv5739239hhsi9if67755gs38',
        DOMAIN: process.env.AWS__COGNITO__DOMAIN || 'healthiq-internal-2023',
        REDIRECT_URI: process.env.AWS__COGNITO__REDIRECT_URI || 'https://crm-staging.healthiq-dev.com/redirect',
        REFRESH_TOKEN_TTL: Number.parseInt(process.env.AWS__COGNITO__REFRESH_TOKEN_TTL || `${THIRTY_DAYS}`),
      },
      API_GATEWAY: {
        STORE_ANSWERS: {
          URL: process.env.AWS__API_GATEWAY__STORE_ANSWERS_URL || 'https://8ebno9zd1j.execute-api.us-east-1.amazonaws.com/api',
          API_KEY: process.env.AWS__API_GATEWAY__STORE_ANSWERS_API_KEY || '8wFgeD1mOP3uUQubyg0KM8zRhJfhkNGD3vMlncWp',
          RETRIES: process.env.AWS__API_GATEWAY__STORE_ANSWERS_RETRIES || 3,
        },
      },
    },
    OAUTH: {
      REDIRECT_URI: process.env.OAUTH__REDIRECT_URI || 'https://crm-staging.healthiq-dev.com/oauth',
    },
    USE_MOCK: {
      DIALER_SERVICE: true,
    },
    PERISCOPE: {
      MEDICARE_SALES_DASHBOARD: process.env.PERISCOPE__MEDICARE_SALES_DASHBOARD
        || 'https://app.periscopedata.com/shared/855f8724-7562-4998-bc62-b751b65c1bba',
      PNC_SALES_DASHBOARD: process.env.PERISCOPE__PNC_SALES_DASHBOARD
        || 'https://app.periscopedata.com/shared/0e4199f1-74b3-4f16-ad0a-f0457afe6f59',
    },
    FEATURE_FLAGS: {},
    CSG: {
      EAPP_HOST: process.env.CSG__EAPP_HOST || 'https://eapp-staging.csgactuarial.com',
    },
    AMPLITUDE: {
      RATE_LIMIT: Number(process.env.AMPLITUDE__RATE_LIMIT) || 10,
      ENABLED: process.env.AMPLITUDE__ENABLED ? !!process.env.AMPLITUDE__ENABLED : false,
    },
    SUNFIRE: {
      HOST: 'https://training.sunfirematrix.com',
    },
  },
  uat: {
    ENV: 'uat',
    HIQ_CRM: {
      HOST: process.env.HIQ_CRM__HOST || 'https://hiq-crm-training.healthiq-dev.com',
      GRAPHQL_ENDPOINT: process.env.HIQ_CRM__GRAPHQL_ENDPOINT || 'graphql',
      SPEED_DIAL_MAPPING_ENDPOINT: process.env.HIQ_CRM__SPEED_DIAL_MAPPING_ENDPOINT || 'speed-dial-mapping',
    },
    AWS: {
      COGNITO: {
        REGION: process.env.AWS__COGNITO__REGION || 'us-east-1',
        USER_POOL_ID: process.env.AWS__COGNITO__USER_POOL_ID || 'us-east-1_n0D86Bu59',
        CLIENT_ID: process.env.AWS__COGNITO__CLIENT_ID || '58cndehu8gk3df455u6ho6is1p',
        DOMAIN: process.env.AWS__COGNITO__DOMAIN || 'healthiq-internal-2023',
        REDIRECT_URI: process.env.AWS__COGNITO__REDIRECT_URI || 'https://crm-uat.healthiq-dev.com/redirect',
        REFRESH_TOKEN_TTL: Number.parseInt(process.env.AWS__COGNITO__REFRESH_TOKEN_TTL || `${THIRTY_DAYS}`),
      },
      API_GATEWAY: {
        STORE_ANSWERS: {
          URL: process.env.AWS__API_GATEWAY__STORE_ANSWERS_URL || 'https://8ebno9zd1j.execute-api.us-east-1.amazonaws.com/api',
          API_KEY: process.env.AWS__API_GATEWAY__STORE_ANSWERS_API_KEY || '8wFgeD1mOP3uUQubyg0KM8zRhJfhkNGD3vMlncWp',
          RETRIES: process.env.AWS__API_GATEWAY__STORE_ANSWERS_RETRIES || 3,
        },
      },
    },
    OAUTH: {
      REDIRECT_URI: process.env.OAUTH__REDIRECT_URI || 'https://crm-uat.healthiq-dev.com/oauth',
    },
    USE_MOCK: {
      DIALER_SERVICE: false,
    },
    PERISCOPE: {
      MEDICARE_SALES_DASHBOARD: process.env.PERISCOPE__MEDICARE_SALES_DASHBOARD
        || 'https://app.periscopedata.com/shared/855f8724-7562-4998-bc62-b751b65c1bba',
      PNC_SALES_DASHBOARD: process.env.PERISCOPE__PNC_SALES_DASHBOARD
        || 'https://app.periscopedata.com/shared/0e4199f1-74b3-4f16-ad0a-f0457afe6f59',
    },
    FEATURE_FLAGS: {},
    CSG: {
      EAPP_HOST: process.env.CSG__EAPP_HOST || 'https://eapp-staging.csgactuarial.com',
    },
    AMPLITUDE: {
      RATE_LIMIT: Number(process.env.AMPLITUDE__RATE_LIMIT) || 10,
      ENABLED: process.env.AMPLITUDE__ENABLED ? !!process.env.AMPLITUDE__ENABLED : false,
    },
    SUNFIRE: {
      HOST: 'https://training.sunfirematrix.com',
    },
  },
  production: {
    ENV: 'production',
    HIQ_CRM: {
      HOST: process.env.HIQ_CRM__HOST || 'https://hiq-crm.healthiq.com',
      GRAPHQL_ENDPOINT: process.env.HIQ_CRM__GRAPHQL_ENDPOINT || 'graphql',
      SPEED_DIAL_MAPPING_ENDPOINT: process.env.HIQ_CRM__SPEED_DIAL_MAPPING_ENDPOINT || 'speed-dial-mapping',
    },
    AWS: {
      COGNITO: {
        REGION: process.env.AWS__COGNITO__REGION || 'us-east-1',
        USER_POOL_ID: process.env.AWS__COGNITO__USER_POOL_ID || 'us-east-1_n0D86Bu59',
        CLIENT_ID: process.env.AWS__COGNITO__CLIENT_ID || 'vavcmmld987ho9gv5d3juj3s2',
        DOMAIN: process.env.AWS__COGNITO__DOMAIN || 'healthiq-internal-2023',
        REDIRECT_URI: process.env.AWS__COGNITO__REDIRECT_URI || 'https://crm.healthiq.com/redirect',
        REFRESH_TOKEN_TTL: Number.parseInt(process.env.AWS__COGNITO__REFRESH_TOKEN_TTL || `${THIRTY_DAYS}`),
      },
      API_GATEWAY: {
        STORE_ANSWERS: {
          URL: process.env.AWS__API_GATEWAY__STORE_ANSWERS_URL || 'https://8ebno9zd1j.execute-api.us-east-1.amazonaws.com/api',
          API_KEY: process.env.AWS__API_GATEWAY__STORE_ANSWERS_API_KEY || '8wFgeD1mOP3uUQubyg0KM8zRhJfhkNGD3vMlncWp',
          RETRIES: process.env.AWS__API_GATEWAY__STORE_ANSWERS_RETRIES || 3,
        },
      },
    },
    OAUTH: {
      REDIRECT_URI: process.env.OAUTH__REDIRECT_URI || 'https://crm.healthiq.com/oauth',
    },
    USE_MOCK: {
      DIALER_SERVICE: false,
    },
    PERISCOPE: {
      MEDICARE_SALES_DASHBOARD: process.env.PERISCOPE__MEDICARE_SALES_DASHBOARD
        || 'https://app.periscopedata.com/shared/855f8724-7562-4998-bc62-b751b65c1bba',
      PNC_SALES_DASHBOARD: process.env.PERISCOPE__PNC_SALES_DASHBOARD
        || 'https://app.periscopedata.com/shared/0e4199f1-74b3-4f16-ad0a-f0457afe6f59',
    },
    FEATURE_FLAGS: {},
    CSG: {
      EAPP_HOST: process.env.CSG__EAPP_HOST
        || 'https://www.insurancetoolsportal.com/healthiq/auth/signin?next=https%3A%2F%2Feapp.insurancetoolsportal.com',
    },
    AMPLITUDE: {
      RATE_LIMIT: Number(process.env.AMPLITUDE__RATE_LIMIT) || 10,
      ENABLED: process.env.AMPLITUDE__ENABLED ? !!process.env.AMPLITUDE__ENABLED : false,
    },
    SUNFIRE: {
      HOST: 'https://www.sunfirematrix.com',
    },
  },
};

type ConfigInterface = typeof configByEnv.production;
type NodeEnv = 'development' | 'staging' | 'uat' | 'production';
const nodeEnv: NodeEnv = (process.env.HIQ_BUILD_ENV || process.env.HIQ_NODE_ENV || process.env.NODE_ENV || 'production') as NodeEnv;
export const config: ConfigInterface = configByEnv[nodeEnv];
